import "./App.css";
import { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./components/Routes";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Main />
        </Router>
      </div>
    );
  }
}

export default App;

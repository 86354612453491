import React, { useRef, useState, useEffect } from "react";
import Homenavbar from "./homenavbar";
import Footer from "./footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import image from "./images/union.png";
import emailjs from "@emailjs/browser";
export const Home = () => {
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_t1l1nq4",
        "template_z3zqy9w",
        form.current,
        "H0hXJzCaKEPZhdFKi"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setLoading(false);
          alert(
            "Message sent successfully  we will get back to you as soon as possible"
          );
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  useEffect(() => {
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select("#navbar.scrollto", true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };
    window.addEventListener("load", navbarlinksActive);
    onscroll(document, navbarlinksActive);

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }
  });
  function ikimina() {
    window.open("https://its-mis.com/", "_blank");
  }
  function has() {
    window.open("https://www.emlinker.com/", "_blank");
  }
  function kabeho() {
    window.open("https://kabehodesigns.com/", "_blank");
  }

  return (
    <div>
      <Homenavbar />
      <section
        id="hero"
        className="d-flex flex-column justify-content-end align-items-center "
        data-aos="fade-up"
        data-aos-delay="150"
        style={{
          backgroundImage: "url('/images/overi.png') ",
          height: "100vh",
          width: "100%",
          position: "relative",
          zIndex: "0",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="overlayside">
          <div className="sekama">
            <h1 className="newarr">Sekadi</h1>

            <p className=" Welcome mt-3">
              IT Solutions, Software Development & Consulting Services
            </p>

            <div className="Welcome mt-4">
              <button className="getstarted scrollto" to="/Main/contact">
                <a href="#aboutus" className="shopnoww">
                  More about us
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section id="aboutus" class="aboutus">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 portfolio-container ">
            <div className="col-xl-6 col-md-6 portfolio-item filter-app im-fluid  ">
              <h3 className="titleabout">WE ARE SEKADI</h3>
              <p>
                SEKADI Ltd is a technology company that provides various
                solutions in areas of Information Technology management,
                software and systems development and consulting services to
                customers around the world.
              </p>
              <div className="row services">
                <div className="col-6">
                  <div class="icon-box">
                    <h4>Vision</h4>
                    <p>
                      Be a true global leader providing best-in-class technology
                      services and helping companies to grow.
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div class="icon-box">
                    <h4>Mission</h4>
                    <p>
                      Empower businesses towards Market Leadership, Inovation
                      and Modernalization through cutting edge technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 portfolio-item  filter-app im-fluid  ">
              <LazyLoadImage src={image} className="fore" />
            </div>
          </div>
        </div>
      </section>

      <section id="whatwedo" className="whatwedo">
        <div className="container " data-aos="fade-up">
          <h3 className="titleabouts">WHAT WE DO</h3>

          <div class="row services" data-aos="fade-up" data-aos-delay="200">
            <div class="col-md-4">
              <div class="icon-box">
                <h4>Software development</h4>
                <p>
                  We offer web and mobile application development services
                  tailored to business needs from ideation all the way through
                  to launch.
                </p>
              </div>
            </div>

            <div class="col-md-4 mt-4 mt-md-0">
              <div class="icon-box">
                <h4>Information System Management</h4>
                <p>
                  Plan, deploy and maintain your company's data systems allowing
                  the company to focus on their core businesses knowing IT
                  infrastructure is secure.
                </p>
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-md-0">
              <div class="icon-box">
                <h4>IT Management Consulting</h4>
                <p>
                  Help businesses evaluate their IT Management and systems needs
                  and match them with the right solutions. We help them through
                  the transformation that bring real value to the business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="project" class="team section-bg p-5">
        <div class="container" data-aos="fade-up">
          <h3 className="titleabout">PROJECTS</h3>
          <p>These are some of our many reference project done by Sekadi:</p>
          <div class="row mt-5">
            <div class="col-lg-4 col-md-6" onClick={ikimina}>
              <div class="member" data-aos="fade-up" data-aos-delay="100">
                <div class="pic">
                  <LazyLoadImage
                    src="/images/ikimina.png"
                    class="photo img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info">
                  <h4>Ikimana </h4>
                  <span>
                    This software system is used by members to manager the
                    operations of Ikimina.{" "}
                  </span>{" "}
                  <span className="visit">vist</span>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
              onClick={has}
            >
              <div class="member">
                <div class="pic">
                  <LazyLoadImage
                    src="/images/has.png"
                    class="photo img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info">
                  <h4>Emlinker</h4>
                  <span>
                    This is an Estate Management Software. It helps estate
                    owners and landlords manage their assets, rentals, incident
                    and payment record.
                  </span>
                  <span className="visit">vist</span>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
              onClick={kabeho}
            >
              <div class="member">
                <div class="pic">
                  <LazyLoadImage
                    src="/images/kabeho.png"
                    class="photo img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info">
                  <h4>Kabeho Designs</h4>
                  <span>E-commerce System</span>
                  <span className="visit">vist</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" class="contact">
        <div class="container " data-aos="fade-up">
          <div className="about ml-3">
            <h3 className="titleabout">CONTACT US</h3>
            <p>
              Please leave us a message and we will get back to you as soon as
              possible:
            </p>
          </div>

          <div class="row  mb-4  rowse">
            <div class="col-lg-6 mt-4 mt-lg-0">
              <form
                action="forms/contact.php"
                method="post"
                ref={form}
                onSubmit={sendEmail}
                class="php-email-form"
              >
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      class="form-controle"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      class="form-controle"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>

                <div class="form-group ">
                  <textarea
                    class="form-controlem"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>

                <div class="text-center">
                  <button type="submit" value="Submit">
                    {" "}
                    {loading ? (
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      " Send Message"
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="info-box">
                    <h3>Our Address</h3>
                    <p>Kimironko-Kigali-Rwanda</p>
                    <h3>Call Us</h3>
                    <p>+250 780 312 140</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;

import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class Homenavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };
  opentoogle = () => {
    this.setState({
      openModal: true,
    });
    console.log("he");
  };
  product = (catego) => {
    window.location.href =
      window.location.origin + "/Main/Category/" + String(catego);
  };

  render() {
    return (
      <div>
        <header id="header" className="fixed-top  ">
          <div className="container d-flex align-items-center justify-content-between mt-2">
            <h1 className="logo ">
              <img src="/images/logo.png" alt="" className="img-fluid" />{" "}
              <span className="logspan">SEKA Di</span>
            </h1>
            <button
              className="mobile-nav-toggleo "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.opentoogle}
            >
              <i class="fas fa-bars"></i>
            </button>
            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a href="/" className="nav-link scrollto " to="/">
                    HOME
                  </a>
                </li>

                <li>
                  <a
                    href="#aboutus"
                    className="nav-link scrollto"
                    to="/Main/contact"
                  >
                    WHO WE ARE
                  </a>
                </li>

                <li>
                  <a
                    href="#whatwedo"
                    className="nav-link scrollto"
                    to="/Main/contact"
                  >
                    WHAT WE DO
                  </a>
                </li>
                <li>
                  <a
                    href="#project"
                    className="nav-link scrollto"
                    to="/Main/contact"
                  >
                    PROJECTS
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className="nav-link scrollto"
                    to="/Main/contact"
                  >
                    CONTACT
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="schoolModal">
            <Modal show={this.state.openModal} className="modalAll">
              <Modal.Body className="Madl">
                <div>
                  <p className="closeModale" onClick={this.closeModal}>
                    <i className="fas fa-times"></i>
                  </p>
                </div>
                <div>
                  <p>
                    <a href="/" className="nav-link scrollto " to="/">
                      HOME
                    </a>
                  </p>

                  <p>
                    <a
                      href="#aboutus"
                      className="nav-link scrollto"
                      to="/Main/contact"
                    >
                      WHO WE ARE
                    </a>
                  </p>

                  <p>
                    <a
                      href="#whatwedo"
                      className="nav-link scrollto"
                      to="/Main/contact"
                    >
                      WHAT WE DO
                    </a>
                  </p>
                  <p>
                    <a
                      href="#project"
                      className="nav-link scrollto"
                      to="/Main/contact"
                    >
                      PROJECTS
                    </a>
                  </p>
                  <p>
                    <a
                      href="#contact"
                      className="nav-link scrollto"
                      to="/Main/contact"
                    >
                      CONTACT
                    </a>
                  </p>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </header>
      </div>
    );
  }
}

export default Homenavbar;
